<template>
  <BRow>
    <BCol
      cols="12"
      class="mb-2"
    >
      <span class="text-[32px] text-black">
        <strong>
          Dashboard
        </strong>
      </span>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="mb-3 justify-content-around">

          <BCol cols="6">
            <div class="card-users mb-2 !border-l-[12px] !border-l-[#F08519] py-1">
              <div class="d-flex align-items-center px-1">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/profile-tick.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black font-semibold">
                  User Terdaftar
                </span>
              </div>

              <div class="p-1 space-y-1 border-b border-[#E2E2E2]">
                <BRow class="">
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Total
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ userRegisterCount.total_count }}
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>

              <BRow class="px-1 pt-1">
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Minggu ini
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ userRegisterCount.weekly_count }}
                    </span>
                    <b-img
                      :src="calculateChange(userRegisterCount, 'weekly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(userRegisterCount, 'weekly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(userRegisterCount, 'weekly').difference }} User
                    </span>
                  </div>
                </BCol>
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Bulan ini
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ userRegisterCount.monthly_count }}
                    </span>
                    <b-img
                      :src="calculateChange(userRegisterCount, 'monthly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(userRegisterCount, 'monthly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(userRegisterCount, 'monthly').difference }} User
                    </span>
                  </div>
                </BCol>
              </BRow>
            </div>
          </BCol>
          <BCol cols="6">
            <div class="card-users mb-2 !border-l-[12px] !border-l-[#6D3CB8] py-1">
              <div class="d-flex align-items-center px-1">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user-octagon.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black font-semibold">
                  Subscribes
                </span>
              </div>

              <div class="p-1 space-y-1 border-b border-[#E2E2E2]">
                <BRow class="">
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Total
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ '-' }}
                      </span>
                    </div>
                  </BCol>
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Aktif
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ '-' }}
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>

              <BRow class="px-1 pt-1">
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Aktif minggu ini
                    <b-img
                      v-b-popover.hover.bottom="`Total user aktif yang subscribe di minggu ini.`"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle-dark.svg"
                      alt="info-circle"
                      class="inline mb-[3px]"
                      width="20"
                    />
                  </div>
                  <div>
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ '-' }}
                    </span>
                  </div>
                </BCol>
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Aktif bulan ini
                    <b-img
                      v-b-popover.hover.bottom="`Total user aktif yang subscribe di bulan ini.`"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle-dark.svg"
                      alt="info-circle"
                      class="inline mb-[3px]"
                      width="20"
                    />
                  </div>
                  <div>
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ '-' }}
                    </span>
                  </div>
                </BCol>
              </BRow>
            </div>
          </BCol>
          <BCol cols="6">
            <div class="card-users mb-2 !border-l-[12px] !border-l-[#08A0F7] py-1">
              <div class="d-flex align-items-center px-1">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/link-1.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black font-semibold">
                  Checkout Link
                </span>
              </div>

              <div class="p-1 space-y-1 border-b border-[#E2E2E2]">
                <BRow class="">
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Total
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ checkoutLinksCount.total_count }}
                      </span>
                    </div>
                  </BCol>
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Aktif
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ checkoutLinksCount.active_total_count }}
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>

              <BRow class="px-1 pt-1">
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Aktif minggu ini
                    <b-img
                      v-b-popover.hover.bottom="`Total Checkout Link aktif yang dibuat minggu ini.`"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle-dark.svg"
                      alt="info-circle"
                      class="inline mb-[3px]"
                      width="20"
                    />
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ checkoutLinksCount.this_week }}
                    </span>
                    <b-img
                      :src="calculateChange(checkoutLinksCount, 'weekly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(checkoutLinksCount, 'weekly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(checkoutLinksCount, 'weekly').difference }} User
                    </span>
                  </div>
                </BCol>
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Aktif bulan ini
                    <b-img
                      v-b-popover.hover.bottom="`Total Checkout Link aktif yang dibuat bulan ini.`"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle-dark.svg"
                      alt="info-circle"
                      class="inline mb-[3px]"
                      width="20"
                    />
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ checkoutLinksCount.this_month }}
                    </span>
                    <b-img
                      :src="calculateChange(checkoutLinksCount, 'monthly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(checkoutLinksCount, 'monthly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(checkoutLinksCount, 'monthly').difference }} User
                    </span>
                  </div>
                </BCol>
              </BRow>
            </div>
          </BCol>
          <BCol cols="6">
            <div class="card-users mb-2 !border-l-[12px] !border-l-[#34A770] py-1">
              <div class="d-flex align-items-center px-1">
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/box.svg"
                  class="mr-50"
                />
                <span class="text-[16px] text-black font-semibold">
                  Orderan
                </span>
              </div>

              <div class="p-1 space-y-1 border-b border-[#E2E2E2]">
                <BRow class="">
                  <BCol cols="6">
                    <div class="text-[#626262] text-[16px]">
                      Total
                    </div>
                    <div>
                      <span class="text-[#333] text-[34px] font-semibold">
                        {{ ordersCount.total_count }}
                      </span>
                    </div>
                  </BCol>
                </BRow>
              </div>

              <BRow class="px-1 pt-1">
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Minggu ini
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ ordersCount.this_week }}
                    </span>
                    <b-img
                      :src="calculateChange(ordersCount, 'weekly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(ordersCount, 'weekly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(ordersCount, 'weekly').difference }} User
                    </span>
                  </div>
                </BCol>
                <BCol cols="6">
                  <div class="text-[#626262] text-[16px]">
                    Bulan ini
                  </div>
                  <div class="flex gap-6">
                    <span class="text-[#333] text-[20px] font-semibold">
                      {{ ordersCount.this_month }}
                    </span>
                    <b-img
                      :src="calculateChange(ordersCount, 'monthly').image"
                      alt="indicator"
                      width="20"
                    />
                    <span
                      :class="calculateChange(ordersCount, 'monthly').textColor"
                      class="text-[20px]"
                    >
                      {{ calculateChange(ordersCount, 'monthly').difference }} User
                    </span>
                  </div>
                </BCol>
              </BRow>
            </div>
          </BCol>

        </BRow>

        <BRow>
          <BCol cols="12">
            <Chart />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard body-class="custom-card-body">
        <BRow class="justify-content-between align-items-center p-2">
          <BCol cols="auto">
            <span class="text-black text-[18px]">
              <strong>
                Detail Users
              </strong>
            </span>
          </BCol>
          <BCol
            cols="auto"
            class="d-flex align-items-center space-x-4"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <BImg
                  class="input-group-text pr-0"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                  width="45"
                  style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"
                />
              </div>
              <input
                v-model="searchUser"
                type="text"
                class="form-control border-left-0"
                placeholder="Cari Pengguna"
                style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"
                @input="searchsUserDetails()"
              >
            </div>

            <div class="dropdown dropdown-chart !min-w-[200px]">
              <button
                class="btn d-flex align-items-center w-full justify-content-between"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ dropdownUserDetail.find(item => item.value === userDetail.sort_by).label }}
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownUserDetail"
                  :key="index"
                  class="dropdown-item"
                  @click="filter(item.value)"
                >{{ item.label }}</a>
              </div>
            </div>

            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'left'"
              class="withdrawal-date-range !min-w-[200px]"
            >
              <template v-slot:input="picker">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="mr-1">
                    <span
                      v-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)"
                      style="color: #828282 !important"
                    >
                      Hari ini
                    </span>
                    <span
                      v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)"
                      style="color: #828282 !important"
                    >
                      Kemarin
                    </span>
                    <span
                      v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)"
                      style="color: #828282 !important"
                    >
                      7 Hari Terakhir
                    </span>
                    <span
                      v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last30)"
                      style="color: #828282 !important"
                    >
                      30 Hari Terakhir
                    </span>
                    <span
                      v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(firstDateOfMonth) && YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)"
                      style="color: #828282 !important"
                    >
                      Bulan ini
                    </span>
                    <span
                      v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(firstDateOfMonthBefore) && YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(lastDateOfMonthBefore)"
                      style="color: #828282 !important"
                    >
                      Bulan Lalu
                    </span>
                    <span
                      v-else
                      style="color: #828282 !important"
                    >
                      Custom Range
                    </span>
                  </div>
                  <BImg
                    class="ml-1"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                  />
                </div>
              </template>
            </DateRangePicker>

          </BCol>
        </BRow>

        <BRow class="px-1">
          <div
            class="table-responsive table-user-detail"
          >
            <table class="table">
              <thead class="custom-head">
                <tr>
                  <td>
                    <span>
                      <strong>
                        Tanggal Register
                      </strong>
                    </span>
                  </td>
                  <td>
                    <span>
                      <strong>
                        Nama
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Email
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        No Whatsapp
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Checkout Link Aktif
                      </strong>
                    </span>
                  </td>
                  <td scope="col">
                    <span>
                      <strong>
                        Total Order
                      </strong>
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userDetail.listItem"
                  :key="index"
                  class="text-black custom-body"
                >
                  <td>
                    {{ DATE_NUMBER_SLASH(item.register_date) }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.no_whatsapp }}</td>
                  <td>{{ item.active_checkout_links }}</td>
                  <td>{{ item.total_order }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </BRow>

        <BRow class="p-2 justify-content-between">
          <BCol
            cols="auto"
            class="d-flex align-items-center"
          >
            <div class="mr-1">
              <span class="text-black">
                List per halaman:
              </span>
            </div>
            <div class="wrapper-perpage d-flex align-items-center justify-content-around">
              <div
                v-for="(item, index) in perPageUserDetail"
                :key="index"
                :class="item === userDetail.perPage ? 'perpage active' : 'perpage'"
                @click="setPageUserDetail(item)"
              >
                {{ item }}
              </div>
            </div>
          </BCol>

          <BCol cols="auto">
            <b-pagination
              v-model="userDetail.currentPage"
              :total-rows="userDetail.totalRows"
              :per-page="userDetail.perPage"
            />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="mb-2">
          <span class="text-black text-[18px] ml-1">
            <strong>
              Checkout Link
            </strong>
          </span>
        </BRow>

        <BRow class="mb-2">
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-all-link.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Semua
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.all }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-link-active.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Aktif
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.active }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
          <BCol
            sm="4"
            xs="6"
            class="mb-1"
          >
            <div class="card-checkout-link d-flex align-items-center p-1">
              <div class="mr-2">
                <BImg src="https://storage.googleapis.com/komerce/assets/illustration/checkout-not-active-link.svg" />
              </div>
              <div class="d-flex flex-column">
                <span class="text-black mb-50">
                  <strong>
                    Tidak Aktif
                  </strong>
                </span>

                <span class="text-black text-[20px]">
                  <strong>
                    {{ elementCountStatusItem.inactive }}
                  </strong>
                </span>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow class="mb-2">
          <span class="text-black text-[18px] ml-1">
            <strong>
              Statistik Penggunaan Elemen
            </strong>
          </span>
        </BRow>

        <BRow class="justify-content-between align-items-center">
          <BCol
            cols="auto"
            class="d-flex flex-column"
          >
            <span class="text-black text-[16px]">
              <strong>
                Elemen Standar
              </strong>
            </span>
          </BCol>

          <BCol cols="auto">
            <div class="dropdown dropdown-chart-link">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterElementStd }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownElementChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusStandartChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol cols="12">
            <ChartStandarElements ref="ChartStandarElements" />
          </BCol>
        </BRow>

        <BRow class="mb-2">
          <BCol cols="12">
            <div style="height: 1px; width: 100%; background: black;" />
          </BCol>
        </BRow>

        <BRow class="justify-content-between align-items-center">
          <BCol
            cols="auto"
          >
            <span class="text-black text-[16px]">
              <strong>
                Elemen Custom
              </strong>
            </span>
          </BCol>

          <BCol cols="auto">
            <div class="dropdown dropdown-chart-link">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterElementCustom }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownElementChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusCustomChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol cols="12">
            <ChartCustomELements ref="ChartCustomELements" />
          </BCol>
        </BRow>
      </BCard>
    </BCol>

    <BCol
      cols="12"
      class="p-0"
    >
      <BCard>
        <BRow class="justify-content-between align-items-center mb-2">
          <BCol cols="auto">
            <span class="text-black text-[18px]">
              <strong>
                Total Orderan
              </strong>
            </span>
          </BCol>
          <BCol
            cols="auto"
            class="d-flex align-items-center"
          >
            <div class="dropdown dropdown-chart-total">
              <button
                class="btn d-flex align-items-center"
                type="button"
                data-toggle="dropdown"
                style="padding: 8px 8px!important"
              >
                {{ valueFilterStatusChart }}
                <BImg
                  class="ml-1"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                />
              </button>
              <div
                class="dropdown-menu"
                style="z-index: 99;"
              >
                <a
                  v-for="(item, index) in dropdownStatusChart"
                  :key="index"
                  class="dropdown-item"
                  @click="filterStatusCountChart(item)"
                >{{ item.label }}</a>
              </div>
            </div>
          </BCol>
        </BRow>

        <BRow>
          <BCol
            sm="3"
            xs="12"
            class="mb-1"
          >
            <ul class="list-group wrapper-list-status-order">
              <li
                v-for="(item, index) in ordersCountStatusItem"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center text-black"
              >
                <span
                  :class="colourList(item.label)"
                  class="w-5 h-5 rounded-circle"
                />
                {{ showLabelOrdersStatus(item.label) }}
                <strong>{{ item.label === 'CLOSING_RATIO' ? item.value.toFixed(2) : item.value }}</strong>
              </li>
            </ul>
          </BCol>

          <BCol
            sm="9"
            xs="12"
            class="w-full"
          >
            <ChartsOrderan :params="valueFilterStatusChart" />
          </BCol>
        </BRow>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  firstDateOfMonthBefore,
  lastDateOfMonthBefore,
  last30,
  last7,
  last2,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, DAY_MONTH_YEAR, YEAR_MONTH_DAY, DATE_NUMBER_SLASH,
} from '@/libs/filterDate'

import Chart from './components/Charts.vue'
import ChartStandarElements from './components/ChartStandarElements.vue'
import ChartCustomELements from './components/ChartCustomElements.vue'
import ChartsOrderan from './components/ChartsOrderan.vue'

export default {
  components: {
    Chart,
    ChartStandarElements,
    ChartCustomELements,
    ChartsOrderan,
    DateRangePicker,
  },
  data() {
    return {
      dateRange: {
        startDate: today,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini ': [today, today],
        'Kemarin ': [last2, last2],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Bulan Lalu': [firstDateOfMonthBefore, lastDateOfMonthBefore],
        'Custom Range': [null, null],
      },
      firstDateOfMonthBefore,
      lastDateOfMonthBefore,
      firstDateOfMonth,
      last30,
      last7,
      last2,
      today,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      DATE_NUMBER_SLASH,

      dropdownUserDetail: [
        {
          label: 'Terbaru',
          value: 'USER_LATEST',
        },
        {
          label: 'Terdahulu',
          value: 'USER_OLDEST',
        },
        {
          label: 'Order Terbanyak',
          value: 'ORDER',
        },
        {
          label: 'CO Link Terbanyak',
          value: 'CHECKOUT_LINK',
        },
      ],

      dropdownElementChart: [
        {
          label: 'Semua Form',
          value: 'SEMUA_FORM',
        },
        {
          label: 'Form Aktif',
          value: 'FORM_AKTIF',
        },
      ],

      dropdownStatusChart: [
        {
          label: 'Bulan Ini',
          value: 'BULAN_INI',
        },
        {
          label: 'Minggu Ini',
          value: 'MINGGU_INI',
        },
        {
          label: 'Semua',
          value: 'SEMUA_WAKTU',
        },
      ],
      valueFilterElementStd: 'Semua Form',
      valueFilterElementCustom: 'Semua Form',
      searchUser: '',
      valueFilterStatusChart: 'Semua',

      perPageUserDetail: [5, 20, 50],
    }
  },
  computed: {
    ...mapState('komformDashboard', [
      'userRegisterCount',
      'userDetail',
      'checkoutLinksCount',
      'ordersCount',
      'elementCountStatusItem',
      'ordersCountStatusItem',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'userDetail.currentPage': function () {
      this.actionsUserDetails()
    },

    dateRange: {
      handler() {
        this.actionsUserDetails()
      },
      deep: true,
    },
  },
  async mounted() {
    this.actionsRegisterCount()
    this.actionsUserDetails()
    this.actionCheckoutLinksCount()
    this.actionOrdersCount()
    this.actionOrdersCountStatus()
    this.actionOrderCountStatusChart()
    await this.$store.dispatch('komformDashboard/elementsCountStatus')
  },
  methods: {
    actionsRegisterCount() {
      this.$store.dispatch('komformDashboard/registerCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionsUserDetails() {
      this.$store.dispatch('komformDashboard/listUserDetails',
        {
          page: this.userDetail.currentPage,
          limit: this.userDetail.perPage,
          username: this.searchUser,
          start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
          end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
          sort_by: this.userDetail.sort_by,
        })
        .then(
          () => {},
          err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
          },
        )
    },
    searchsUserDetails: _.debounce(function search(text) {
      this.actionsUserDetails()
    }, 500),
    actionCheckoutLinksCount() {
      this.$store.dispatch('komformDashboard/checkoutLinksCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrdersCount() {
      this.$store.dispatch('komformDashboard/orderCount').then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrdersCountStatus() {
      const payload = this.dropdownStatusChart.find(item => item.label === this.valueFilterStatusChart)
      this.$store.dispatch('komformDashboard/orderCountStatus', payload.value).then(
        () => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    actionOrderCountStatusChart() {
      const payload = this.dropdownStatusChart.find(item => item.label === this.valueFilterStatusChart)
      this.$store.dispatch('komformDashboard/orderCountStatusChart', payload.value).then(() => {},
        err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    showLabelOrdersStatus(data) {
      let result
      switch (data) {
        case ('CLOSING_RATIO'):
          result = 'Ratio Closing'
          break
        case ('ORDER'):
          result = 'Order'
          break
        case ('CLOSING'):
          result = 'Closing'
          break
        case ('TIDAK_CLOSING'):
          result = 'Tidak Closing'
          break
        default:
          break
      }
      return result
    },
    colourList(data) {
      let result
      switch (data) {
        case ('CLOSING_RATIO'):
          result = 'bg-[#08A0F7]'
          break
        case ('ORDER'):
          result = 'bg-[#FBA63C]'
          break
        case ('CLOSING'):
          result = 'bg-[#34A770]'
          break
        case ('TIDAK_CLOSING'):
          result = 'bg-[#F95031]'
          break
        default:
          break
      }
      return result
    },
    filterStatusCountChart(state) {
      this.valueFilterStatusChart = state.label
      this.actionOrderCountStatusChart()
      this.actionOrdersCountStatus()
    },
    filter(state) {
      this.userDetail.sort_by = state
      this.actionsUserDetails()
    },
    setPageUserDetail(page) {
      this.userDetail.perPage = page
      this.actionsUserDetails()
    },
    filterStatusCustomChart(state) {
      this.valueFilterElementCustom = state.label
      this.$refs.ChartCustomELements.setFilter(state)
    },
    filterStatusStandartChart(state) {
      this.valueFilterElementStd = state.label
      this.$refs.ChartStandarElements.setFilter(state)
    },
    calculateChange(data, type) {
      let difference = type === 'weekly' ? data.delta_this_week : data.delta_this_month

      let textColor
      let image
      if (difference > 0) {
        textColor = 'text-[#34A770]'
        image = 'https://storage.googleapis.com/komerce/assets/icons/file-icons/arrow-up.svg'
      } else if (difference < 0) {
        textColor = 'text-[#E31A1A]'
        image = 'https://storage.googleapis.com/komerce/assets/icons/file-icons/arrow-down.svg'
      } else {
        textColor = 'text-[#FBA63C]'
        image = 'https://storage.googleapis.com/komerce/assets/icons/minus-cirlce.svg'
      }

      difference = Math.abs(difference)

      return {
        textColor,
        difference,
        image,
      }
    },
  },
}
</script>

<style scoped>
  .card-users {
    border-radius: 8px;
    border: 0px solid #E2E2E2;
    background: #fff;
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
    min-width: 350px;
    min-height: 175px;
  }

  .dropdown-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    width: 180px;
  }

  .custom-head {
    height: 50px;
    background: #F4F4F4;
    border-bottom: 2px solid #E2E2E2;
  }

  .custom-body {
    height: 60px;
  }

  .custom-card-body {
    padding: 0;
  }

  .wrapper-perpage {
    border-radius: 12px;
    background: #F8F8F8;
    width: 150px;
    height: 40px;
  }

  .perpage {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
  }

  .perpage:hover {
    background:#ffbcb0;
    color: white;
  }
  .perpage.active {
    border-radius: 8px;
    background: #F95031;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
  }

  .card-checkout-link {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .dropdown-chart-link, .dropdown-chart-total {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .table-user-detail {
    height: 370px;
  }
</style>
