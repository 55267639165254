<template>
  <div
    id="chart"
    class="w-full wrapper-chart"
  >
    <VueApexCharts
      type="area"
      height="350"
      :options="chartOptions"
      :series="seriesStatusChart"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'

export default {
  components: { VueApexCharts },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        colors: ['#34A770', '#F95031', '#FBA63C', '#08A0F7'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'number',
          categories: [],
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
      },
    }
  },
  computed: {
    ...mapState('komformDashboard', [
      'seriesStatusChart',
    ]),
  },
}
</script>

<style scoped>
  .wrapper-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }

  .dropdown-chart {
    border-radius: 8px;
    border: 1px solid #E2E2E2;
  }
</style>
